import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import Background from "../images/invitacion/Background.webp"
import Texto from "../images/invitacion/Texto.webp"
import styled from "styled-components"
import LandingUno from "../images/invitacion/LandingUno.webp"
import LandingDos from "../images/invitacion/LandingDos.webp"

const TitleBox = styled.div`
    background-color: #0B2A3B;
    opacity: 91%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;

    img {
        width: 500px;
    }

    @media only screen and (max-width: 520px) {
        img {
            width: 350px;
        }
    }
`
const FirstLanding = styled.div`

    img {
        width: 100vw;
    }
`

const FormDiv = styled.div`
    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5%;
    }

    h2 {
        font-size: 25px;
        font-family: 'M Bold';
        margin-bottom: 50px;
    }

    form>div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        width: 70vw;
    }
    form>div:nth-child(2) input { 
        width: 33vw;
    }

    input, textarea {
        background-color: #EBEBEB;
        border: none;
        width: 70vw;
        margin-bottom: 20px;
        font-family: 'M Medium';
        height: 40px;
        font-size: 20px;
    }

    textarea:focus, input:focus{
        outline: none;
    }

    textarea {
        height: 200px;
    }

    .submit-input {
        width: 20vw;
        background-color: #808083;
        font-family: 'M Bold';
        color: white;
    }

    @media only screen and (max-width: 1040px) {
        .submit-input {
            width: 23vw;
            font-size: 2vw;
        }
    }

    @media only screen and (max-width: 660px) {
        .submit-input {
            width: 35vw;
            font-size: 3vw;
        }
        input, textarea {
        font-size: 15px;
    }
}
`

const SecondLanding = styled.div`

    img {
        width: 100vw;
    }
`

function Invitacion() {
    const [hasMounted, setHasMounted] = useState(false) 

    useEffect(() => {
        setHasMounted(true)
    }, [])

return hasMounted ? (        
        <Layout>
            <TitleBox>
                <img src={Texto} alt="Texto" />
            </TitleBox>
            <FirstLanding>
                <img src={LandingUno} alt="LandingUno" />
            </FirstLanding>
            <FormDiv>
                <form action="https://formspree.io/f/meqnpzjq" method="POST">
                    <h2>REGÍSTRATE AQUÍ</h2>
                    <div>
                        <input type="text" placeholder='Nombre' name='Nombre' />
                        <input type="text" placeholder='Apellido' name='Apellido' />
                    </div>
                    <input type="text" placeholder='Correo' name='reply_to' />
                    <input type="text" placeholder='Teléfono' name='teléfono' />
                    <textarea placeholder='Mensaje' name="mensaje" cols="30" rows="10"></textarea>
                    <input type="submit" className='submit-input' value='REGíSTRATE AQUÍ'/>
                </form>
                
            </FormDiv>
            <SecondLanding>
                <img src={LandingDos} alt="LandingDos" />
            </SecondLanding>
            <TitleBox>
                <img src={Texto} alt="Texto" />
            </TitleBox>
        </Layout>
    ) : (
        null
    )
}

export default Invitacion